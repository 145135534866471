import axios from "axios";

export default {
  endpointRootUrl: "/api/process",
  generate(data) {
    let axiosConfig = {};

    return axios
      .post(this.endpointRootUrl + "/generate", data, axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug("generate", response);
        return response.data;
      });
  },
};
