import API from "@/js/api/projects.js";

const defaultState = {
  currentProject: {},
  projects: []
};

const actions = {
  GET_PROJECT(context, data) {
    Logger.debug("GET_PROJECT", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("PROJECT ACTION GET_PROJECT success", response);
        // commit mutation
        context.commit("setCurrentProject", response);
      })
      .catch(error => {
        Logger.debug("PROJECT ACTION GET_PROJECT error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_PROJECTS: (context, data) => {
    Logger.debug("GET_PROJECTS", data);
    return API.getDocuments(data)
      .then(response => {
        Logger.debug("PROJECT ACTION GET_PROJECTS success", response);
        // commit mutation
        context.commit("setProjects", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("PROJECT ACTION GET_PROJECTS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  PUT_PROJECT(context, data) {
    Logger.debug("PUT_PROJECT",data);
    return API.updateDocument(data)
      .then(response => {
        Logger.debug("PROJECT ACTION PUT_PROJECT success", response);
        // commit mutation
        context.commit("setCurrentProject", response);
        return response;
      })
      .catch(error => {
        Logger.debug("PROJECT ACTION PUT_PROJECT error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_PROJECTS(context, data) {
    Logger.debug("EXPORT_PROJECTS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("ACTION EXPORT_PROJECTS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("ACTION EXPORT_PROJECTS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_PROJECTS(context, data) {
    Logger.debug("IMPORT_PROJECTS", data);
    return API.import(data)
      .then(response => {
        Logger.debug("GET_CHEMFAMILY ACTION IMPORT_PROJECTS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("GET_CHEMFAMILY ACTION IMPORT_PROJECTS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_PROJECT: (context, data) => {
    Logger.debug("REMOVE_PROJECT", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("PROJECTS ACTION REMOVE_PROJECT success", response);
        // commit mutation
        context.commit("removeProject", data);
        return response;
      })
      .catch(error => {
        Logger.debug("PROJECTS ACTION REMOVE_PROJECT Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setCurrentProject(state, payload) {
    state.currentProject = payload;
  },
  setProjects(state, payload) {
    state.projects = payload;
  },
  removeProject(state, id) {
    const i = state.projects.map(item => item._id).indexOf(id);
    state.projects.splice(i, 1);
  },
};

const getters = {
  currentProject: state => {
    return state.currentProject;
  },
  projects: state => {
    return state.projects;
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
