<template>
<div class="row">
  <div class="col-12 ml-auto mr-auto">
    <pre>{{ InfoData }}</pre>
    <!--
    <pre>{{ ConfigX }}</pre>
    -->
  </div>
</div>
</template>
<script>

  export default {
    components: {},
    data() {
      return {
        infoData: {}
      }
    },
    mounted() {
      this.$store.dispatch("GET_INFO", {})
        .then(data => {
          Logger.debug("GET_INFO", data)
          this.infoData = data;
        })
    },
    computed: {
      InfoData: {
        get() {
          // transform if string => obj
          return this.infoData;
        },
        set(value) {
          this.infoData = value;
        }
      }
    },
    methods: {},
  }
</script>
<style>
</style>
