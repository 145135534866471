import axios from "axios";

export default {
  endpointRootUrl: "/api/admin",

  /**
   * Same as in results.api
   * @param data
   * @returns {Promise<unknown>|Promise<AxiosResponse<any>>}
   */
  invite(data) {
    let axiosConfig = {};
    Logger.debug("admin api: invite", data);

    return axios
      .post(this.endpointRootUrl + "/invite", data, axiosConfig)
      .then(response => {
        Logger.debug("admin api: invite resp", data);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  },

  ////////////////////// user administration
  /**
   * set user options.confirmed to true
   * @param data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  confirm(data) {
    let axiosConfig = {};
    Logger.trace("admin api: confirm ", data);
    const userId = data.userId;
    delete data.userId;

    return axios
      .put(this.endpointRootUrl + "/user/" + userId + "/confirm", data, axiosConfig)
      .then(response => {
        Logger.trace("admin api: confirm response", response.data);
        return response.data;
      });
  },
  /**
   * set user options.disabled to body.disabled value true or false
   * @param data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  disable(data) {
    let axiosConfig = {};
    Logger.trace("admin api: disable ", data);
    const userId = data.userId;
    delete data.userId;

    return axios
      .put(this.endpointRootUrl + "/user/" + userId + "/disable", data, axiosConfig)
      .then(response => {
        Logger.trace("admin api: disable response", response.data);
        return response.data;
      });
  },
  changeRole(data) {
    let axiosConfig = {};
    Logger.trace("admin api: changeRole ", data);
    const userId = data.userId;
    delete data.userId;

    return axios
      .put(this.endpointRootUrl + "/user/" + userId + "/role", data, axiosConfig)
      .then(response => {
        Logger.trace("admin api: changeRole response", response.data);
        return response.data;
      });
  },
};
