<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>

                  <fg-input v-model="form.email" :required="true" addon-left-icon="nc-icon nc-email-85"
                            placeholder="My Email..."></fg-input>

                  <fg-input v-model="form.password" :required="true" addon-left-icon="nc-icon nc-key-25" placeholder="My Password"
                            type="password"></fg-input>

                  <br>
                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Login</p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-zurich2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    data() {
      return {
        form: {
          email: '',
          password: ''
        }
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login() {
        // handle login here
        let payloadObj = {
          email: this.form.email,
          password: this.form.password
        };
        this.LoadingX = true;
        this.$store
          .dispatch("LOGIN", payloadObj)
          .then(val => {
            this.$router.push('/dashboard');
          })
          .catch(error => {
            //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
            Logger.debug("error", error);

            this.$notify({
              title: "Login failed",
              message:'Please enter a correct email/password.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
