import generic from "@/js/services/generic";

/**
 * Generic API class which uses generic methods from generic.js
 */
export default class API {
  constructor(endpoint) {
    this.endpointRootUrl = endpoint;
  }

  async getDocument(documentId) {
    return generic.getDocument(this.endpointRootUrl, documentId);
  }

  async getDocuments(data) {
    return generic.getDocuments(this.endpointRootUrl, data);
  }

  async deleteDocument(documentId) {
    return generic.deleteDocument(this.endpointRootUrl, documentId);
  }

  async createDocument(data) {
    return generic.createDocument(this.endpointRootUrl, data);
  }

  async updateDocument(data) {
    return generic.updateDocument(this.endpointRootUrl, data);
  }

  // TODO updateDocument
  async export(reqData) {
    return generic.export(this.endpointRootUrl, reqData);
  }

  async import(data) {
    return generic.import(this.endpointRootUrl, data);
  }
}
