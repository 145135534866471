<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="resetPassword">
                <card type="login">
                  <h3 slot="header" class="header text-center">Reset Password</h3>

                  <fg-input v-model="form.password" :required="true" addon-left-icon="nc-icon nc-key-25" placeholder="My new Password"
                            type="password"></fg-input>
                  <br>
                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Reset password</p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-zurich2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },

    data() {
      return {
        form: {
          id: "",
          token: "",
          password: ""
        }
      }
    },
    mounted() {
      Logger.debug("$route.params.id", this.$route.params.id, this.$route.params.token)
      this.form.id = this.$route.params.id;
      this.form.token = this.$route.params.token;
    },

    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      resetPassword() {
        // handle login here
        let payloadObj = {
          id: this.form.id,
          token: this.form.token,
          password: this.form.password
        };
        this.LoadingX = true;
        this.$store
          .dispatch("RESET_PASSWORD", payloadObj)
          .then(val => {
            this.$notify({
              title: "Reset password success",
              message: "You can login with your new password.",
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
            this.$router.push({name: 'Login'});
          })
          .catch(error => {
            Logger.debug("error", error);
            this.$notify({
              title: "Reset password failed",
              message:'Token is invalid. Please try again.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
