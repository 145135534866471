// predefined locations aka names in local storage.
const STORAGE_LOCATION = {
  user: "user",
  token: "user-token",
  refreshToken: "user-refresh-token"
};

class TokenService {
  /**
   * Get user from local storage.
   * @returns {any}
   */
  getLocalUser() {
    return JSON.parse(localStorage.getItem(STORAGE_LOCATION.user));
  }

  /**
   * Gets token from local storage.
   * @returns {any}
   */
  getLocalAccessToken() {
    const token = localStorage.getItem(STORAGE_LOCATION.token);
    return token;
    //return user?.accessToken;
  }

  /**
   * Gets refresh token from local storage.
   * @returns {any}
   */
  getLocalRefreshToken() {
    const refreshToken = localStorage.getItem(STORAGE_LOCATION.refreshToken);
    return refreshToken;
    // return user?.accessToken;
  }

  /**
   * Sets user in local storage.
   * @param user
   */
  setLocalUser(user) {
    localStorage.setItem(STORAGE_LOCATION.user, JSON.stringify(user));
  }

  /**
   * Sets token in local storage.
   * @param token
   */
  setLocalAccessToken(token) {
    localStorage.setItem(STORAGE_LOCATION.token, token);
    /*
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
    */
  }

  /**
   * Sets refresh token in local storage.
   * @param token
   */
  setLocalRefreshToken(token) {
    localStorage.setItem(STORAGE_LOCATION.refreshToken, token);
  }

  /**
   * Removes user from local storage.
   */
  removeLocalUser() {
    localStorage.removeItem(STORAGE_LOCATION.user);
  }

  /**
   * Removes token from local storage.
   */
  removeLocalToken() {
    localStorage.removeItem(STORAGE_LOCATION.token);
  }

  /**
   * Remove refresh token from local storage.
   */
  removeLocalRefreshToken() {
    localStorage.removeItem(STORAGE_LOCATION.refreshToken);
  }

  /**
   * Remove token and refresh token from local storage.
   */
  removeLocalEntries() {
    this.removeLocalUser();
    this.removeLocalToken();
    this.removeLocalRefreshToken()
  }
}

export default new TokenService();