<template>
  <div class="user">
    <div class="photo">
      <!--
      <img src="static/img/faces/face-2.jpg" alt="user avatar"/>
      -->
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">

        <span>
          Dev Menue
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="https://api.foodpackagingforum.org/api-docs" target="_blank">
                <span class="sidebar-mini-icon">REST</span>
                <span class="sidebar-normal">API Public</span>
              </a>
            </li>
            <li>
              <a href="https://api.foodpackagingforum.org/api-docs-internal" target="_blank">
                <span class="sidebar-mini-icon">REST</span>
                <span class="sidebar-normal">API Internal</span>
              </a>
            </li>
            <li>
              <router-link to="/mapping/overview">
                <span class="sidebar-mini-icon">MAP</span>
                <span class="sidebar-normal">MAPPING</span>
              </router-link>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        isClosed: true
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
