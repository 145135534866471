import API from "@/js/api/mappings.js";

const defaultState = {
  mappings: [],
  currentMapping: {}
};

const actions = {
  GET_MAPPING(context, data) {
    Logger.debug("GET_MAPPING", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("ACTION GET_MAPPING success", response);
        // commit mutation
        context.commit("setCurrentMapping", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_MAPPING error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_MAPPINGS: (context, data) => {
    Logger.debug("GET_MAPPINGS", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("GET_MAPPING ACTION GET_MAPPINGS success", response);
        // commit mutation
        context.commit("setMappings", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("GET_MAPPING ACTION GET_MAPPINGS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_LATEST_MAPPING(context) {
    Logger.debug("GET_LATEST_MAPPING");
    return API.getLatest()
      .then(response => {
        Logger.debug("ACTION GET_LATEST_MAPPING success", response);
        // commit mutation
        context.commit("setCurrentMapping", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_LATEST_MAPPING error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_MAPPINGS(context, data) {
    Logger.debug("EXPORT_MAPPINGS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("ACTION EXPORT_MAPPINGS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("ACTION EXPORT_MAPPINGS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  CREATE_MAPPING(context, data) {
    Logger.debug("CREATE_MAPPING", data);
    return API.createDocument(data)
      .then(response => {
        Logger.debug("ACTION CREATE_MAPPING success", response);
        // commit mutation
        context.commit("setCurrentMapping", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION CREATE_MAPPING error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setMappings(state, payload) {
    state.mappings = payload;
  },
  setCurrentMapping(state, payload) {
    state.currentMapping = payload;
  }
};

const getters = {
  mappings: state => {
    return state.mappings;
  },
  currentMapping: state => {
    return state.currentMapping;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
