import API from "@/js/api/process.js";


const defaultState = {

};

const actions = {
  GENERATE_MASTER(context, data) {
    Logger.debug("GENERATE_MASTER", data);
    return API.generate(data)
      .then(response => {
        Logger.debug("MASTER ACTION GENERATE_MASTER success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("MASTER ACTION GENERATE_MASTER error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {

};

const getters = {

};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
