import axios from "axios";
import qs from "query-string";

/**
 * Generic API Service for CRUD operations.
 */

export default {
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getDocument(endpointRootUrl, documentId) {
    let axiosConfig = {};
    if (!documentId) {
      documentId = "";
    }
    Logger.debug(endpointRootUrl + " api: getDocument by documentId", documentId);
    return axios
      .get(endpointRootUrl + "/" + documentId, axiosConfig)
      .then(response => {
        // Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug(endpointRootUrl + " api: getResults resp", response);
        return response.data;
      });
  },

  /**
   * Get result for user or for all users
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  getDocuments(endpointRootUrl, data) {
    let axiosConfig = {};
    let qsResult = qs.stringify(data);
    qsResult = qsResult ? "?" + qsResult : "";
    Logger.debug("sources api: getDocuments", data, qsResult);
    return axios
      .get(endpointRootUrl + qsResult, axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug("getResults resp", response);
        return response.data;
      });
  },

  deleteDocument(endpointRootUrl, documentId) {
    Logger.debug("deleteDocument", documentId);
    let axiosConfig = {};
    return axios
      .delete(endpointRootUrl + "/" + documentId, axiosConfig)
      .then(response => {
        Logger.debug("deleteDocument resp", response);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  },

  createDocument(endpointRootUrl, data) {
    let axiosConfig = {};
    Logger.debug("createDocument", data);
    return axios
      .post(endpointRootUrl, data, axiosConfig)
      .then(response => {
        Logger.debug("createDocument resp", response);
        return response.data;
      });
  },

  // TODO updateDocument
  // TODO post/create document
  updateDocument(endpointRootUrl, data) {
    let axiosConfig = {};
    Logger.debug("updateDocument", data);
    return axios
      .put(endpointRootUrl, data, axiosConfig)
      .then(response => {
        Logger.debug("updateDocument resp", response);
        return response.data;
      });
  },

  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  export(endpointRootUrl, reqData) {

    reqData = reqData || {
      "format": "json"
    };
    //reqData.q = JSON.stringify({"name": "FCCdb"});// only test
    const splitArray = endpointRootUrl.split("/");
    const file = splitArray[splitArray.length - 1];

    const filename = file + "-export" + (reqData.format === "json" ? ".json" : ".csv");

    let axiosConfig = {
      headers: {
        Accept: reqData.format === "json" ? "application/json" : "text/csv",
        // CORS policy: Request header field contentdisposition
        //contentDisposition: `attachment; filename="${filename}"`
      },
      responseType: "blob", // not for json
      timeout: 0,
      //responseType: 'stream',
      /*
      onDownloadProgress: function ({loaded, total, progress, bytes, estimated, rate, download = true}) {
        // Do whatever you want with the Axios progress event
        console.log("onDownloadProgress", loaded, total, progress, bytes, estimated, rate, download);
      },
      */
    };

    //set query string
    let qsResult = qs.stringify(reqData);
    qsResult = qsResult ? "?" + qsResult : "";
    Logger.debug("export qsResult", qsResult);

    return axios
      .get(endpointRootUrl + "/export" + qsResult, axiosConfig)
      .then(response => {

        //Logger.trace("api: contact success", response);
        // create file link in browser's memory
        Logger.debug("response.data", response.data);
        //let jsonString = JSON.stringify(response.data);
        //let blob = new Blob([jsonString], { type:"application/json" })
        let blob = new Blob([response.data], {type: "application/json"})
        //let blob = new Blob([response.data], { type:"text/csv" })
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);


      });
  },

  import(endpointRootUrl, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 0
    };
    Logger.debug("uploadResults", data);
    return axios
      .post(endpointRootUrl + "/import", data, axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug("uploadResults", response);
        return response.data;
      });
  },

  // TODO duplicate in global mixin => move to lib.js
  flattenArray: function (data, remFieldsArray) {
    let retArray = [];
    for (let i = 0, len = data.length; i < len; i++) {
      for (let j = 0, lenRem = remFieldsArray.length; j < lenRem; j++) {
        delete data[i][remFieldsArray[j]];
      }
      retArray.push(this.flattenObj(data[i]));
    }
    return retArray;
  },
  // TODO duplicate in global mixin => move to lib.js
  flattenObj: function (data) {
    var result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++)
          recurse(cur[i], prop + "[" + i + "]");
        if (l == 0)
          result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }
        if (isEmpty && prop)
          result[prop] = {};
      }
    }

    recurse(data, "");
    return result;
  }
}
