import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  Logger.debug("ifNotAuthenticated", store.getters.isAuthenticated);
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  Logger.debug("ifAuthenticated", store.getters.isAuthenticated);
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const Dashboard = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Dashboard.vue')
const Master = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Masters.vue')
const Sources = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Sources.vue')
const Extractions = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Extractions.vue')
const Chems = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Chems.vue')
const ChemFamilies = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/ChemFamilies.vue')

const ChemFunctions = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/ChemFunctions.vue')


const Users = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Users.vue')
const UserDetails = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/User/UserDetails.vue')
const MyProfile = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/User/MyProfile.vue')

const Projects = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Projects.vue')

const Mapping = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Mappings.vue')

const Tools = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Admin/Tools.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import ForgotPassword from 'src/components/Dashboard/Views/Pages/ForgotPassword.vue'
import ResetPassword from 'src/components/Dashboard/Views/Pages/ResetPassword.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Info from 'src/components/Dashboard/Views/Pages/Info.vue'

///////////////////////// DEMO
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

  // Dashboard pages
  const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
  const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')


// Forms pages
  const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
  const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
  const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
  const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
  const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
  const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
  const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');


  let componentsMenu = {
    path: '/components',
    component: DashboardLayout,
    redirect: '/components/buttons',
    children: [
      {
        path: 'buttons',
        name: 'Buttons',
        component: Buttons
      },
      {
        path: 'grid-system',
        name: 'Grid System',
        component: GridSystem
      },
      {
        path: 'panels',
        name: 'Panels',
        component: Panels
      },
      {
        path: 'sweet-alert',
        name: 'Sweet Alert',
        component: SweetAlert
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons
      },
      {
        path: 'typography',
        name: 'Typography',
        component: Typography
      }

    ]
  }

  let formsMenu = {
    path: '/forms',
    component: DashboardLayout,
    redirect: '/forms/regular',
    children: [
      {
        path: 'regular',
        name: 'Regular Forms',
        component: RegularForms
      },
      {
        path: 'extended',
        name: 'Extended Forms',
        component: ExtendedForms
      },
      {
        path: 'validation',
        name: 'Validation Forms',
        component: ValidationForms
      },
      {
        path: 'wizard',
        name: 'Wizard',
        component: Wizard
      }
    ]
  }

  let tablesMenu = {
    path: '/table-list',
    component: DashboardLayout,
    redirect: '/table-list/regular',
    children: [
      {
        path: 'regular',
        name: 'Regular Tables',
        component: RegularTables
      },
      {
        path: 'extended',
        name: 'Extended Tables',
        component: ExtendedTables
      },
      {
        path: 'paginated',
        name: 'Paginated Tables',
        component: PaginatedTables
      }]
  }

  let pagesMenu = {
    path: '/pages',
    component: DashboardLayout,
    redirect: '/pages/user',
    children: [
      {
        path: 'user',
        name: 'User Page',
        component: User
      },
      {
        path: 'timeline',
        name: 'Timeline Page',
        component: TimeLine
      }
    ]
  }


  let lockPage = {
    path: '/lock',
    name: 'Lock',
    component: Lock
  }

//////////////////////////// DEMO END

let dashboardMenu = {
  path: '/', //path: '/dashboard',
  component: DashboardLayout,
  redirect: "/dashboard",
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard
    }
  ]
}

let mastersMenu = {
  path: '/master',
  component: DashboardLayout,
  redirect: '/master/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Master Overview',
      component: Master
    }
  ]
}

let sourcesMenu = {
  path: '/sources',
  component: DashboardLayout,
  redirect: '/sources/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Sources Overview',
      component: Sources
    }
  ]
}

let extractionsMenu = {
  path: '/extractions',
  component: DashboardLayout,
  redirect: '/extractions/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Extractions Overview',
      component: Extractions
    }
  ]
}

let chemsMenu = {
  path: '/chems',
  component: DashboardLayout,
  redirect: '/chems/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Chems Overview',
      component: Chems
    }
  ]
}

let chemFamiliesMenu = {
  path: '/chemFamilies',
  component: DashboardLayout,
  redirect: '/chemFamilies/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Chem Families Overview',
      component: ChemFamilies
    }
  ]
}

let chemFunctionsMenu = {
  path: '/chemFunctions',
  component: DashboardLayout,
  redirect: '/chemFunctions/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Chem Functions Overview',
      component: ChemFunctions
    }
  ]
}



let projectsMenu = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Projects Overview',
      component: Projects
    }
  ]
}

let mappingMenu = {
  path: '/mappings',
  component: DashboardLayout,
  redirect: '/mappings/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Mapping Overview',
      component: Mapping
    }
  ]
}

let usersMenu = {
  path: '/users',
  component: DashboardLayout,
  redirect: '/users/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Users Overview',
      component: Users
    },
    {
      path: 'details/:id',
      name: 'User Details',
      component: UserDetails
    },
    {
      path: 'myprofile',
      name: 'My Profile',
      component: MyProfile
    }
  ]
}


let toolsMenu = {
  path: '/tools',
  component: DashboardLayout,
  redirect: '/tools/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Tools Overview',
      component: Tools
    }
  ]
}


let loginPage = {
  path: '/login',
  name: 'Login',
  beforeEnter: ifNotAuthenticated,
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  beforeEnter: ifNotAuthenticated,
  component: Register
}

let forgotPasswordPage = {
  path: '/forgotPassword',
  name: 'ForgotPassword',
  beforeEnter: ifNotAuthenticated,
  component: ForgotPassword
}

let resetPasswordPage = {
  path: '/resetpassword/:id/:token',
  name: 'resetPassword',
  beforeEnter: ifNotAuthenticated,
  component: ResetPassword
}

let infoMenu = {
  path: '/info',
  component: DashboardLayout,
  redirect: '/info/overview',
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'overview',
      name: 'Info Overview',
      component: Info
    }
  ]
}

const routes = [
  dashboardMenu,
  mastersMenu,
  chemsMenu,
  sourcesMenu,
  extractionsMenu,
  chemsMenu,
  chemFamiliesMenu,
  chemFunctionsMenu,
  projectsMenu,
  usersMenu,
  infoMenu,
  mappingMenu,
  toolsMenu,

  loginPage,
  forgotPasswordPage,
  resetPasswordPage,
  //registerPage,

  // demo
  componentsMenu,
  formsMenu,
  tablesMenu,
  pagesMenu,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {path: '*', component: NotFound}
];

// configure router
const router = new Router({
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});


//TODO improve this part
router.beforeEach((to, from, next) => {
  Logger.debug("store isDataLoaded:", store.getters.isDataLoaded);
  Logger.debug("store isAuthenticated:", store.getters.isAuthenticated);
  Logger.debug("router.beforeEach", to, from, next);

  if (store.getters.isAuthenticated && !store.getters.isDataLoaded) {
    store.commit("setLoading", true);
    // avoid multiple refreshes - only check is user can be fetched
    store
      .dispatch("GET_CURRENT_USER")
      .then(user => {
        Logger.debug("current user found:", user);

        Promise.all([
          store.dispatch("GET_CONFIG")
        ])
          .then(() => {
            /*
            // FIXME does not work with logger the global one is defined in js-logger-init
            const devMode = store.getters.getDevMode; //process.env.NODE_ENV !== 'production';
            const debug = store.getters.getDebug;
            // trace if in devMode if not only warn
            Logger.setLevel(Logger.ERROR);
            //Logger.setLevel((devMode || debug) ? Logger.WARN : Logger.ERROR);
            //Logger.setLevel(Logger.OFF);
            console.warn("Loglevel:", devMode || debug,  Logger.getLevel());
            */
            // using "finally" so even if there are errors, it stops "loading"
            Logger.trace("DISPATCHER DATA successful");
            //Logger.trace("store", store);
            // set defaultProject in settings as currentProject
            next();
          })
          .catch(error => {
            Logger.error("ROUTER DISPATCHER Loading all Error");
            throw error;
          })
      })
      .catch(error => {
        Logger.error("ROUTER DISPATCHER GET_CURRENT_USER Error");
      })
      .finally(() => {
        Logger.info("DISPATCHER GET_CONFIG in router finished");
        store.commit("setLoading", false);
      });
  } else {
    Logger.trace("no store dispatched");
    //afterLoading(to, from, next);
    //loadLanguageAsync(lang).then(afterLoading(to, from, next));
    //return ifNotAuthenticated(to, from, next);
    store.commit("setLoading", false);
    next();
  }
});


export default router
