import API from "@/js/api/extractions.js";

const defaultState = {
  extractions: [],
  currentExtraction: {}
};

const actions = {
  GET_EXTRACTION(context, data) {
    Logger.debug("GET_EXTRACTION", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("ACTION GET_EXTRACTION success", response);
        // commit mutation
        context.commit("setCurrentExtraction", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_EXTRACTION error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_EXTRACTIONS: (context, data) => {
    Logger.debug("GET_EXTRACTIONS", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("EXTRACTIONS ACTION GET_EXTRACTIONS success", response);
        // commit mutation
        context.commit("setExtractions", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("EXTRACTIONS ACTION GET_EXTRACTIONS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_EXTRACTIONS(context, data) {
    Logger.debug("EXPORT_EXTRACTIONS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("EXTRACTIONS ACTION EXPORT_EXTRACTIONS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("EXTRACTIONS ACTION EXPORT_EXTRACTIONS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_EXTRACTIONS(context, data) {
    Logger.debug("IMPORT_EXTRACTIONS", data);
    return API.import(data)
      .then(response => {
        Logger.debug("EXTRACTIONS ACTION IMPORT_EXTRACTIONS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("EXTRACTIONS ACTION IMPORT_EXTRACTIONS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_EXTRACTION: (context, data) => {
    Logger.debug("REMOVE_EXTRACTION", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("EXTRACTIONS ACTION REMOVE_EXTRACTION success", response);
        // commit mutation
        context.commit("removeExtraction", data);
        return response;
      })
      .catch(error => {
        Logger.debug("EXTRACTIONS ACTION REMOVE_EXTRACTION Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setExtractions(state, payload) {
    state.extractions = payload;
  },
  setCurrentExtraction(state, payload) {
    state.currentExtraction = payload;
  },
  removeExtraction(state, id) {
    const i = state.extractions.map(item => item._id).indexOf(id);
    state.extractions.splice(i, 1);
  },
};

const getters = {
  extractions: state => {
    return state.extractions;
  },
  currentExtraction: state => {
    return state.currentExtraction;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
