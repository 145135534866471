// TODO should be ready via api...
//import { themeConfig } from "@/client/src/js/my/themeConfig";
// https://stackoverflow.com/questions/41835716/how-do-i-set-initial-state-in-vuex-2
// https://stackoverflow.com/questions/41572974/how-to-load-all-server-side-data-on-initial-vue-js-vue-router-load
import API from "@/js/api/info.js";
import Vue from "vue";


//import Languages from '@/i18n';
const supportedLanguages = ["de", "en"]; //Object.getOwnPropertyNames(Languages);

const defaultState = {
  config: {}
  //language: Vue.localStorage.get("language")
};

const actions = {

  SET_LANGUAGE: ({ commit, state }, newValue) => {
    Logger.trace("SET_LANGUAGE", newValue);

    if (typeof newValue === "string") {
      commit("setLanguage", newValue);
    } else {
      const language = supportedLanguages.find(sl =>
        newValue.find(l =>
          l.split(new RegExp(sl, "gi")).length - 1 > 0 ? sl : null
        )
      );
      commit("setLanguage", language);
    }
    return state.language;
  },

  GET_CONFIG(context, data) {
    Logger.trace("GET_CONFIG", this.getters.config);
    Logger.debug("GET_CONFIG ACTION");

    return new Promise(function(resolve) {
      //Logger.debug("CONFIG ACTION GET_CONFIG success", config);
      const config = {}
      context.commit("setConfig", config);
      resolve(config);
    });
  },
  GET_INFO(context, data) {
    Logger.trace("GET_INFO");
    return API.getInfo()
      .then(response => {
        Logger.debug("CONFIG ACTION GET_INFO success", response);
        // commit mutation
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("CONFIG ACTION GET_INFO Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setLanguage: (state, newLang) => {
    Vue.localStorage.set("language", newLang);
    state.language = newLang;
  },
  setConfig(state, newConfig) {
    state.config = newConfig;
  },
  setDevMode(state, newConfig) {
    state.config.dev.devMode = newConfig;
  },
  setDebug(state, newConfig) {
    state.config.dev.debug = newConfig;
  }
};

const getters = {
  language: state => {
    return state.language;
  },
  config: state => {
    return state.config;
  },
  getDevMode: state => {
    return state.config.dev.devMode;
  },
  getDebug: state => {
    return state.config.dev.debug;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
