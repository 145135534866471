import axios from "axios";

export default {
  endpointRootUrl: "/api/auth",
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  refresh(data) {
    let axiosConfig = {};

    return axios
      .post(this.endpointRootUrl + "/refresh", data, axiosConfig)
      .then(response => {
        Logger.trace("auth api: refresh", response);
        //TODO improve check with other solution in auth, user api and setup interceptor
        let token = response.data.token;
        axios.defaults.headers.common['Authorization'] = token;

        return response.data;
      });
  }
};
