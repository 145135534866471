import API from "@/js/api/admin.js";

const defaultState = {

};

const actions = {
  CONFIRM_USER_ADMIN: (context, data) => {
    Logger.debug("ADMIN ACTION CONFIRM_USER_ADMIN", data);
    context.state.loading = true;
    return API.confirm(data)
      .then(response => {
        Logger.debug("ADMIN ACTION CONFIRM_USER_ADMIN success", response);
        // commit mutation
        //context.commit("login", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("ADMIN ACTION CONFIRM_USER_ADMIN Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        //context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  DISABLE_USER_ADMIN: (context, data) => {
    Logger.debug("ADMIN ACTION DISABLE_USER_ADMIN", data);
    context.state.loading = true;
    return API.disable(data)
      .then(response => {
        Logger.debug("ADMIN ACTION DISABLE_USER_ADMIN success", response);
        // commit mutation
        //context.commit("login", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("ADMIN ACTION DISABLE_USER_ADMIN Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        //context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  CHANGE_ROLE_USER_ADMIN: (context, data) => {
    Logger.debug("ADMIN ACTION CHANGE_ROLE_USER_ADMIN", data);
    context.state.loading = true;
    return API.changeRole(data)
      .then(response => {
        Logger.debug("ADMIN ACTION CHANGE_ROLE_USER_ADMIN success", response);
        // commit mutation
        //context.commit("login", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("ADMIN ACTION CHANGE_ROLE_USER_ADMIN Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        //context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  }
};

const mutations = {

};

const getters = {

};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
