<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <a href="https://foodpackagingforum.org" target="_blank" rel="noopener">Food Packaging Forum</a>
            </li>
            <li>
              <a href="https://fcx.foodpackagingforum.org" target="_blank" rel="noopener">Frontend</a>
            </li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright">
            &copy;{{ Year }} v{{ Version }} Coded with
            <i class="fa fa-heart heart"></i> by <a href="https://entre.prenerds.com" target="_blank" rel="noopener">entrepre.nerds</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  import {updateMixin} from "@/js/mixins/update-mixin";

  export default {
    name: 'main-footer',
    mixins: [updateMixin],
    computed: {
      Year() {
        return new Date().getFullYear();
      },
      Version() {
        return process.env.VUE_APP_VERSION;
      },
    }
  }
</script>
<style>
</style>
