//require('js-logger').useDefaults();
export const Logger = require("js-logger");

//Logger.useDefaults();
const devMode = process.env.NODE_ENV !== "production",
  moduleName = process.env.VUE_APP_APPLICATION,
  logLevel = devMode ? Logger.DEBUG : Logger.WARN; // trace if in devMode if not only warn

const existingLogger = global.Logger;

if (existingLogger) {
  let currentLevel = Logger.getLevel();
  currentLevel = existingLogger.getLevel();
  console.warn("Logger already initialized", currentLevel);
  if (currentLevel.value === 99) {
    initializeLogger(logLevel);
  }
  // TODO add case to change log level if current is lower than existing one.
} else {
  initializeLogger(logLevel);
}

function initializeLogger(logLvl) {
  Logger.useDefaults();
  Logger.setLevel(logLvl); // ERROR / it is set again in config router
  let currentLvl = Logger.getLevel();
  console.warn("Logger initialized with logLevel=" + currentLvl.name + " for module="+ moduleName + " in devMode="+ devMode, global.Logger);
}