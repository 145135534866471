import axios from "axios";

import genericApi from "../services/generic";
export default {
  endpointRootUrl: "/api/info",
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getInfo() {
    return genericApi.getDocuments(this.endpointRootUrl, {});
  }
};
