import API from "@/js/api/chemFamilies.js";
import {Constants} from "@/js/const.js";
//import config from 'config';

const defaultState = {
  chemFamilies: [],
  currentChemFamily: {}
};

const actions = {
  GET_CHEMFAMILY(context, data) {
    Logger.debug("GET_CHEMFAMILY", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("ACTION GET_CHEMFAMILY success", response);
        // commit mutation
        context.commit("setCurrentChemFamily", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_CHEMFAMILY error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_CHEMFAMILIES: (context, data) => {
    Logger.debug("GET_CHEMFAMILIES", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("GET_CHEMFAMILY ACTION GET_CHEMFAMILIES success", response);
        // commit mutation
        context.commit("setChemFamilies", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("GET_CHEMFAMILY ACTION GET_CHEMFAMILIES Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_CHEMFAMILIES(context, data) {
    Logger.debug("EXPORT_CHEMFAMILYS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("ACTION EXPORT_CHEMFAMILYS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("ACTION EXPORT_CHEMFAMILYS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_CHEMFAMILIES(context, data) {
    Logger.debug("IMPORT_CHEMFAMILYS", data);
    return API.import(data)
      .then(response => {
        Logger.debug("CHEMFAMILY ACTION IMPORT_CHEMFAMILYS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("CHEMFAMILY ACTION IMPORT_CHEMFAMILYS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_CHEMFAMILY: (context, data) => {
    Logger.debug("REMOVE_CHEMFAMILY", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("CHEMFAMILY ACTION REMOVE_CHEMFAMILY success", response);
        // commit mutation
        context.commit("removeChemFamily", data);
        return response;
      })
      .catch(error => {
        Logger.debug("CHEMFAMILY ACTION REMOVE_CHEMFAMILY Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setChemFamilies(state, payload) {
    state.chemFamilies = payload;
  },
  setCurrentChemFamily(state, payload) {
    state.currentChemFamily = payload;
  },
  removeChemFamily(state, id) {
    const i = state.chemFamilies.map(item => item._id).indexOf(id);
    state.chemFamilies.splice(i, 1);
  },
};

const getters = {
  chemFamilies: state => {
    return state.chemFamilies;
  },
  currentChemFamily: state => {
    return state.currentChemFamily;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
