export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/dashboard'
  },
  {
    name: 'Master',
    icon: 'nc-icon nc-globe',
    path: '/master'
  },
  {
    name: 'Mappings',
    icon: 'nc-icon nc-globe',
    path: '/mappings'
  },
  {
    name: 'Sources',
    icon: 'nc-icon nc-single-copy-04',
    path: '/sources'
  },
  {
    name: 'Extractions',
    icon: 'nc-icon nc-layout-11',
    path: '/extractions'
  },
  {
    name: 'Chems',
    icon: 'nc-icon nc-atom',
    path: '/chems'
  },
  {
    name: 'ChemFamilies',
    icon: 'nc-icon nc-planet',
    path: '/chemFamilies'
  },
  {
    name: 'ChemFunctions',
    icon: 'nc-icon nc-planet',
    path: '/chemFunctions'
  },
  {
    name: 'Projects',
    icon: 'nc-icon nc-box',
    path: '/projects'
  },
  {
    name: 'Users',
    icon: 'nc-icon nc-single-02',
    path: '/users'
  }
]
