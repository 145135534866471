<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.foodpackagingforum.org/">
              Food Packaging Forum
            </a>
          </li>
          <li>
            <a href="https://fcx.foodpackagingforum.org">
              Frontend
            </a>
          </li>

        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy;{{ Year }} v{{ Version }} Coded with
        <i class="fa fa-heart heart"></i> by <a href="https://entre.prenerds.com/" rel="noopener" target="_blank">entrepre.nerds</a>.</span>
      </div>
    </div>
  </footer>
</template>
<script>
  import {updateMixin} from "@/js/mixins/update-mixin";
  export default {
    mixins: [updateMixin],
    computed: {
      Year() {
        return new Date().getFullYear();
      },
      Version() {
        return process.env.VUE_APP_VERSION;
      },
    }
  }

</script>
<style>

</style>
