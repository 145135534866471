import axiosInstance from "./setup-api"; //currently just axios
import TokenService from "../services/token-service";

/**
 * interceptors catch any results and/or errors even before the promises are execute resp. then/error calls.
 * @param store
 */
const setup = (store, router) => {
  axiosInstance.interceptors.request.use(
    (config) => {

      /**
      const token = localStorage.getItem("user-token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
       */
      // TODO this is needed - in api common will be set, but here the config is returned

      //TODO improve check with other solution in auth, user api and setup interceptor
      const token = TokenService.getLocalAccessToken();
      if (token) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["Authorization"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      Logger.error("api interceptor: response error invalid", err.config.url);
      const originalConfig = err.config;

      // last call was an api call (and i.e. not refresh and the first time)
      if (originalConfig.url !== "/api/auth/refresh" &&
        err.response &&
        err.response.status === 401 &&
        !originalConfig._retry
      ) {
        // set retry so same call is not done more than once
        originalConfig._retry = true;

        try {
          // TODO change
          const postData = {
            email: TokenService.getLocalUser().email,
            refreshToken: TokenService.getLocalRefreshToken()
          };
          Logger.debug("api interceptor: response postData", postData);

          return store.dispatch('REFRESH', postData).then(result=> {
            Logger.debug("api interceptor: result", result);
            // this means the api with the original config is excuted again after token is refreshed.
            return axiosInstance(originalConfig);
          });
          //return axiosInstance(originalConfig);

          /*
          const rs = await axiosInstance.post("/api/auth/refresh", postData);
          const { accessToken } = rs.data;
          store.dispatch('auth/refreshToken', accessToken);
          TokenService.updateLocalAccessToken(accessToken);
          */

        } catch (_error) {
          return Promise.reject(_error);
        }
      } else {
        // if refresh was not successful and returns an error
        // TODO check which error codes we need to involve to route to login 404 is for authToken not found in db
        if (originalConfig.url === "/api/auth/refresh" && err.response && (err.response.status === 401 || err.response.status === 404)) {
          store.dispatch("LOGOUT").then( ()=> {
            //window.location.replace('/#/login');
            router.push("/login")
          });
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
