<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-5 ml-auto">
                <info-section class="mt-5"
                              type="primary"
                              title="Backoffice"
                              description="All registrations are subject to approval."
                              icon="nc-icon nc-tv-2">

                </info-section>
              </div>
              <div class="col-lg-4 col-md-6 mr-auto">
                <form @submit.prevent="register">
                  <card type="signup" class="text-center">
                    <template slot="header">
                      <h4 class="card-title">Register</h4>
                    </template>

                    <fg-input v-model="form.name" :required="true" addon-left-icon="nc-icon nc-circle-10" placeholder="Name..."></fg-input>
                    <fg-input v-model="form.email" :required="true" addon-left-icon="nc-icon nc-email-85" placeholder="Email..."></fg-input>
                    <fg-input v-model="form.organisation" :required="true" addon-left-icon="nc-icon nc-bank" placeholder="Organization..."></fg-input>
                    <fg-input v-model="form.password" :required="true" addon-left-icon="nc-icon nc-key-25" placeholder="Password..." type="password"></fg-input>
                    <p-checkbox class="text-left" v-model="form.acceptedTerms" :required="true">
                      I agree to the
                      <a href="#something">terms and conditions</a>.
                    </p-checkbox>

                    <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Register</p-button>
                  </card>
                </form>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background"
             style="background-image: url(static/img/background/background-zurich.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    data(){
      return {
        form: {
          name: '',
          email: '',
          organisation: '',
          password: '',
          acceptedTerms: false
        }
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      register() {
        this.LoadingX = true;
        this.$store
          .dispatch("SIGN_UP", this.form)
          .then(res => {
            this.$notify({
              title: "Registration successful",
              message: "Please wait for the admin to approve your registration. You will receive an email once your account is approved.",
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
            this.$router.push({name: 'Login'});
          })
          .catch(error => {
            //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
            Logger.debug("error", error);

            this.$notify({
              title: "Registration failed",
              message:'Please get in touch with the admin.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
