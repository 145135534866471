import axios from "axios";
import API from "@/js/api/api";

class MappingsApi extends API {
  constructor(endpointRootUrl) {
    super(endpointRootUrl);
  }
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getLatest() {
    let axiosConfig = {};
    Logger.debug("mappings api: getLatest");
    return axios
      .get(this.endpointRootUrl + "/latest", axiosConfig)
      .then(response => {
        // Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug("mappings api: getResults resp", response);
        return response.data;
      });
  }
}
export default new MappingsApi("/api/mappings");

