import API from "@/js/api/chems.js";

const defaultState = {
  chems: [],
  currentChem: {}
};

const actions = {
  GET_CHEM(context, data) {
    Logger.debug("GET_CHEM", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("ACTION GET_CHEM success", response);
        // commit mutation
        context.commit("setCurrentChem", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_CHEM error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_CHEMS: (context, data) => {
    Logger.debug("GET_CHEMS", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("CHEM ACTION GET_CHEMS success", response);
        // commit mutation
        context.commit("setChems", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("CHEM ACTION GET_CHEMS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_CHEMS(context, data) {
    Logger.debug("EXPORT_CHEMS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("CHEM ACTION EXPORT_CHEMS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("CHEM ACTION EXPORT_CHEMS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_CHEMS(context, data) {
    Logger.debug("IMPORT_CHEMS", data);
    return API.import(data)
      .then(response => {
        Logger.debug("CHEM ACTION IMPORT_CHEMS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("CHEM ACTION IMPORT_CHEMS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_CHEM: (context, data) => {
    Logger.debug("REMOVE_CHEM", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("CHEM ACTION REMOVE_CHEM success", response);
        // commit mutation
        context.commit("removeChem", data);
        return response;
      })
      .catch(error => {
        Logger.debug("CHEM ACTION REMOVE_CHEM Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setChems(state, payload) {
    state.chems = payload;
  },
  setCurrentChem(state, payload) {
    state.currentChem = payload;
  },
  removeChem(state, id) {
    const i = state.chems.map(item => item._id).indexOf(id);
    state.chems.splice(i, 1);
  },
};

const getters = {
  chems: state => {
    return state.chems;
  },
  currentChem: state => {
    return state.currentChem;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
