
const moment = require('moment');
export const globalMixin = {
  data: function() {
    return {};
  },
  mounted: function() {
    // Logger.trace("globalMixin", global)
  },
  computed: {
    ConfigX: {
      get() {
        return this.$store.getters.config;
      },
      set(newValue) {
        this.$store.commit("setConfig", newValue);
      }
    },
    DevModeX: {
      get() {
        return this.$store.getters.getDevMode;
      }
    },
    CurrentMasterX: {
      get() {
        return this.$store.getters.currentMaster;
      },
      set(newValue) {
        this.$store.commit("setCurrentMaster", newValue);
      }
    },
    MastersX: {
      get() {
        return this.$store.getters.masters;
      },
      set(newValue) {
        this.$store.commit("setMasters", newValue);
      }
    },

    SourcesX: {
      get() {
        return this.$store.getters.sources;
      },
      set(newValue) {
        this.$store.commit("setSources", newValue);
      }
    },
    ExtractionsX: {
      get() {
        return this.$store.getters.extractions;
      },
      set(newValue) {
        this.$store.commit("setExtractions", newValue);
      }
    },
    ChemsX: {
      get() {
        return this.$store.getters.chems;
      },
      set(newValue) {
        this.$store.commit("setChems", newValue);
      }
    },
    ChemFamiliesX: {
      get() {
        return this.$store.getters.chemFamilies;
      },
      set(newValue) {
        this.$store.commit("setChemFamilies", newValue);
      }
    },

    CurrentUserX: {
      get() {
        return this.$store.getters.currentUser;
      },
      set(newValue) {
        this.$store.commit("setUser", newValue);
      }
    },
    UsersX: {
      get() {
        return this.$store.getters.users;
      },
      set(newValue) {
        this.$store.commit("setUsers", newValue);
      }
    },
    ProjectsX: {
      get() {
        return this.$store.getters.projects;
      },
      set(newValue) {
        this.$store.commit("setProjects", newValue);
      }
    },

    IsAuthenticatedX: function() {
      return this.$store.getters.isAuthenticated;
    },
    LoadingX: {
      get() {
        //Logger.trace("this.$store.getters.loadingX", this.$store.getters.loading);
        return this.$store.getters.loading;
      },
      set(newValue) {
        this.$store.commit("setLoading", newValue);
      }
    }
  },
  methods: {
    openExternal: function(url, newTab) {
      let win = window.open(url, newTab ? "_blank" : "");
      win.focus();
      //location.href = "https://docs.google.com/document/d/11kgcGXL2RFPPdR6iHtnoh5zemgN-EXlaqnJpBJA4JZY"
    },
    /**
     * Returns a random integer between min (inclusive) and max (inclusive)
     * Using Math.round() will give you a non-uniform distribution!
     */
    getRandomInt: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    arrayMove: function(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    getTitleWithArticleId({ articleId, title }) {
      if (articleId && title) {
        return `${articleId}: ${title}`;
      }
      return "";
    },
    //load project either by param or currentProjectX
    setCurrentProject: function(newProject) {
      this.LoadingX = true;
      if (newProject) {
        this.CurrentProjectX = newProject;
      }

      let projectId = this.CurrentProjectX._id,
        payloadProject = { projectId: projectId },
        payloadMeta = { projectId: projectId, mode: "prod" }, // not used yet
        payloadProjectTagTog = { projectId: projectId, onlyTagtog: true };

      Promise.all([
        this.$store.dispatch("GET_SOURCES_BY_PROJECT", { ...payloadProject }),
        this.$store.dispatch("GET_SOURCES_BY_PROJECT", {
          ...payloadProjectTagTog
        }),
        this.$store.dispatch("GET_METAS_BY_PROJECT", { ...payloadProject }),
        this.$store.dispatch("GET_RESULTS_BY_PROJECT", { ...payloadProject }),
        this.$store.dispatch("GET_USERS_ACTIVITY_BY_PROJECT", {
          ...payloadProject
        }),
        this.$store.dispatch("GET_SOURCES_ACTIVITY_BY_PROJECT", {
          ...payloadProject
        })
      ])
        .then(items => {
          //Logger.debug("success setCurrentProject", items);
        })
        .catch(error => {
          console.error("error setCurrentProject", error);
          throw error;
        })
        .finally(() => {
          this.LoadingX = false;
          this.$router.push("/dashboard");
        });
    },

    flattenArray: function(data, remFieldsArray) {
      let retArray = [];
      for (let i = 0, len = data.length; i < len; i++) {
        if (remFieldsArray && remFieldsArray.length > 0) {
          for (let j = 0, lenRem = remFieldsArray.length; j < lenRem; j++) {
            delete data[i][remFieldsArray[j]];
          }
        }
        retArray.push(this.flattenObj(data[i]));
      }
      return retArray;
    },
    flattenObj: function(data) {
      let result = {};
      function recurse(cur, prop) {
        if (Object(cur) !== cur) {
          result[prop] = cur;
        } else if (Array.isArray(cur)) {
          for (let i = 0, l = cur.length; i < l; i++)
            recurse(cur[i], prop + "[" + i + "]");
          if (l == 0) result[prop] = [];
        } else {
          let isEmpty = true;
          for (let p in cur) {
            isEmpty = false;
            recurse(cur[p], prop ? prop + "." + p : p);
          }
          if (isEmpty && prop) result[prop] = {};
        }
      }
      recurse(data, "");
      return result;
    },
    arrayDif: function(a1, a2) {
      let a = [],
        diff = [];

      for (let i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
      }

      for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
          delete a[a2[i]];
        } else {
          a[a2[i]] = true;
        }
      }

      for (let k in a) {
        diff.push(k);
      }

      return diff;
    }
  },
  filters: {
    pretty: function(value) {
      //Logger.trace("pretty", value, JSON.stringify(value));
      return JSON.stringify(value, null, 4);
    },
    truncate: function(valueString, maxLength) {
      if (!maxLength) {
        maxLength = 10;
      }
      let truncatedString = valueString.substring(0, maxLength);
      return truncatedString;
    },
    timestampToString: function(value) {
      //Logger.trace("timestampToString", value);
      if (value) {
        let mom = moment(value);
        return mom.format("DD.MM.YYYY, HH:mm:ss");
      }
      return "";
    },
    timeToString: function(value) {
      //Logger.trace("timestampToString", value);
      if (value) {
        let mom = moment(value);
        return mom.format("HH:mm");
      }
      return "";
    },
    unixTimeToString: function(stamp) {
      //stripe uses unix format timestamps -> convert with moment
      return moment.unix(stamp).format("DD.MM.YYYY, HH:mm:ss");
    },
    reverse: function(array) {
      // slice to make a copy of array, then reverse the copy
      return array.slice().reverse();
    },
    tagtogSource: function(source) {
      if (source && source.tagtog && source.tagtog.id) {
        return " (TagTog)";
      }
      return "";
    },
    settleResults: function(resArray, mode) {
      //"Added " + items.length + " of " + this.importData.length + " results.",
      const retObj = {
        total: resArray.length,
        added: 0,
        duplicates: 0,
        failed: 0
      };
      if (!mode) {
        mode = "Created";
      }

      for (let resItem of resArray) {
        if (resItem.status === "fulfilled") {
          retObj.added++;
        } else {
          retObj.failed++;
          // check if duplicate or failed otherwise
          let reason = resItem.reason,
            code =
              reason &&
              reason.response &&
              reason.response.data &&
              reason.response.data.code;
          // mongo duplicate error code
          if (code === 11000) {
            retObj.duplicates++;
          }
        }
      }

      let retText = "Operation lead to the following results:<br>";
      retText += "Entries found: " + retObj.total;
      if (retObj.added > 0) {
        retText += "<br> " + mode + ":" + retObj.added;
      }
      if (retObj.failed > 0) {
        retText += "<br> Failed:" + retObj.failed;
      }
      if (retObj.duplicates > 0) {
        retText += " due to duplicates " + retObj.duplicates;
      }

      return retText;
    }
  }
};
