import API from "@/js/api/chemFunctions.js";
import {Constants} from "@/js/const.js";
//import config from 'config';

const defaultState = {
  chemFunctions: [],
  currentChemFunction: {}
};

const actions = {
  GET_CHEMFUNCTION(context, data) {
    Logger.debug("GET_CHEMFUNCTION", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("ACTION GET_CHEMFUNCTION success", response);
        // commit mutation
        context.commit("setCurrentChemFunction", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_CHEMFUNCTION error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_CHEMFUNCTIONS: (context, data) => {
    Logger.debug("GET_CHEMFUNCTIONS", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("GET_CHEMFUNCTION ACTION GET_CHEMFUNCTIONS success", response);
        // commit mutation
        context.commit("setChemFunctions", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("GET_CHEMFUNCTION ACTION GET_CHEMFUNCTIONS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_CHEMFUNCTIONS(context, data) {
    Logger.debug("EXPORT_CHEMFUNCTIONS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("ACTION EXPORT_CHEMFUNCTIONS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("ACTION EXPORT_CHEMFUNCTIONS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_CHEMFUNCTIONS(context, data) {
    Logger.debug("IMPORT_CHEMFUNCTIONS", data);
    return API.import(data)
      .then(response => {
        Logger.debug("GET_CHEMFUNCTION ACTION IMPORT_CHEMFUNCTIONS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("GET_CHEMFUNCTION ACTION IMPORT_CHEMFUNCTIONS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_CHEMFUNCTION: (context, data) => {
    Logger.debug("REMOVE_CHEMFUNCTION", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("CHEMFUNCTION ACTION REMOVE_CHEMFUNCTION success", response);
        // commit mutation
        context.commit("removeChemFunction", data);
        return response;
      })
      .catch(error => {
        Logger.debug("CHEMFUNCTION ACTION REMOVE_CHEMFUNCTION Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setChemFunctions(state, payload) {
    state.chemFunctions = payload;
  },
  setCurrentChemFunction(state, payload) {
    state.currentChemFunction = payload;
  },
  removeChemFunction(state, id) {
    const i = state.chemFunctions.map(item => item._id).indexOf(id);
    state.chemFunctions.splice(i, 1);
  },
};

const getters = {
  chemFunctions: state => {
    return state.chemFunctions;
  },
  currentChemFunction: state => {
    return state.currentChemFunction;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
