/*!

  =========================================================
  * Vue Paper Dashboard 2 PRO - v2.4.1
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2022 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/

import Vue from "vue";
import "./pollyfills";
import store from "./store";
// router setup
import router from "./routes/routes";
import VueNotify from "vue-notifyjs";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import App from "./App.vue";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import initProgress from "./progressbar";

// library imports

import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/demo.scss";

import sidebarLinks from "./sidebarLinks";
import "./registerServiceWorker";
// plugin setup
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });
locale.use(lang);


const devMode = process.env.NODE_ENV !== "production";
Vue.prototype.$devMode = devMode;
console.log("devMode", devMode)

import "./js/setup/js-logger-init.js";
//import "./js/setup/setup-api.js";
import setupInterceptors from "./js/setup/setup-interceptors.js";
setupInterceptors(store, router);

import { globalMixin } from "./js/mixins/global-mixin";
Vue.mixin(globalMixin);

/*
import JsonEditorVue from 'json-editor-vue';
Vue.use(JsonEditorVue);
*/

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App)
});
