import API from "@/js/api/sources.js";

const defaultState = {
  sources: [],
  currentSource: {}
};

const actions = {
  GET_SOURCE(context, data) {
    Logger.debug("GET_SOURCE", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("ACTION GET_SOURCE success", response);
        // commit mutation
        context.commit("setCurrentSource", response);
        return response;
      })
      .catch(error => {
        Logger.debug("ACTION GET_SOURCE error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_SOURCES: (context, data) => {
    Logger.debug("GET_SOURCES", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("SOURCE ACTION GET_SOURCES success", response);
        // commit mutation
        context.commit("setSources", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("SOURCE ACTION GET_SOURCES Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_SOURCES(context, data) {
    Logger.debug("EXPORT_SOURCES", data);
    return API.export(data)
      .then(response => {
        Logger.debug("SOURCE ACTION EXPORT_SOURCES success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("SOURCE ACTION EXPORT_SOURCES error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_SOURCES(context, data) {
    Logger.debug("IMPORT_SOURCES", data);
    return API.import(data)
      .then(response => {
        Logger.debug("SOURCE ACTION IMPORT_SOURCES success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
        Logger.debug("SOURCE ACTION IMPORT_SOURCES error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_SOURCE: (context, data) => {
    Logger.debug("REMOVE_SOURCE", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("SOURCES ACTION REMOVE_SOURCE success", response);
        // commit mutation
        context.commit("removeSource", data);
        return response;
      })
      .catch(error => {
        Logger.debug("SOURCES ACTION REMOVE_SOURCE Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setSources(state, payload) {
    state.sources = payload;
  },
  setCurrentSource(state, payload) {
    state.currentSource = payload;
  },
  removeSource(state, id) {
    const i = state.sources.map(item => item._id).indexOf(id);
    state.sources.splice(i, 1);
  },
};

const getters = {
  sources: state => {
    return state.sources;
  },
  currentSource: state => {
    return state.currentSource;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
