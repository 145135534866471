import axios from "axios";
import Vue from "vue";

// FIXME use global var
const devMode = process.env.NODE_ENV !== "production";
axios.defaults.timeout = 20000;
const baseURL = "https://api.foodpackagingforum.org";
if(!devMode) {
  axios.defaults.baseURL = baseURL;
}
//axios.defaults.baseURL = 'http://localhost:3000';
/*
// set this for devMode
let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZTRhN2UyMjkxMDIzZTJhZjRlNDQ2MjUiLCJpYXQiOjE1ODE5NDI1OTh9.WTjy1fGR5l9bMRkVrrsQ0VHpdW9SAyTB1JVQ5VgQV7U";
axios.defaults.headers.common = {'Authorization': `${token}`};
*/
Vue.prototype.$http = axios;

// if token available
const token = localStorage.getItem("user-token");
if (token) {
  //TODO improve check with other solution in auth, user api and setup interceptor
  axios.defaults.headers.common["Authorization"] = token;
}
export default axios;
/*
const instance = axios.create({
  baseURL: "http://localhost:8080/api",
  headers: {
    "Content-Type": "application/json",
  }
});
export default instance;
*/

