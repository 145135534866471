import API from "@/js/api/masters.js";
import {Constants} from "@/js/const.js";
//import config from 'config';

const defaultState = {
  masters: [],
  currentMaster: {}
};

const actions = {
  GET_MASTER(context, data) {
    Logger.debug("GET_MASTER", data);
    return API.getDocument(data)
      .then(response => {
        Logger.debug("MASTER ACTION GET_MASTER success", response);
        // commit mutation
        context.commit("setCurrentMaster", response);
        return response;
      })
      .catch(error => {
        Logger.debug("MASTER ACTION GET_MASTER error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_MASTERS: (context, data) => {
    Logger.debug("GET_MASTERS", data);

    return API.getDocuments(data)
      .then(response => {
        Logger.debug("MASTER ACTION GET_MASTERS success", response);
        // commit mutation
        context.commit("setMasters", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("MASTER ACTION GET_MASTERS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_MASTERS(context, data) {
    Logger.debug("EXPORT_MASTERS", data);
    return API.export(data)
      .then(response => {
        Logger.debug("MASTER ACTION EXPORT_MASTERS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
        Logger.debug("MASTER ACTION EXPORT_MASTERS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  REMOVE_MASTER: (context, data) => {
    Logger.debug("REMOVE_MASTER", data);
    return API.deleteDocument(data)
      .then(response => {
        Logger.debug("MASTERS ACTION REMOVE_MASTER success", response);
        // commit mutation
        context.commit("removeMaster", data);
        return response;
      })
      .catch(error => {
        Logger.debug("MASTERS ACTION REMOVE_MASTER Error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  }
};

const mutations = {
  setMasters(state, payload) {
    state.masters = payload;
  },
  setCurrentMaster(state, payload) {
    state.currentMaster = payload;
  },
  removeMaster(state, id) {
    const i = state.masters.map(item => item._id).indexOf(id);
    state.masters.splice(i, 1);
  },
};

const getters = {
  masters: state => {
    return state.masters;
  },
  currentMaster: state => {
    return state.currentMaster;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
